module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"IT&I Internet-Technologien und Informationsverarbeitung-GmbH","short_name":"IT&I Software","start_url":"/","background_color":"#1d9fb3","theme_color":"#1d9fb3","display":"minimal-ui","icon":"src/images/iti-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a67f66b63c8a4a3f89e76eb08b460fcb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/services/","/technologies/","/about/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
