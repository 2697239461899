require("./src/assets/scss/global.scss")

exports.onRouteUpdate = ({ location }) => {
  if (location.hash) {
    setTimeout(function() {
      document
        .querySelector(`${location.hash}`)
        .scrollIntoView({ block: "start", behavior: "smooth" })
    }, 500)
  }
}

exports.onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
